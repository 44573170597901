import React, { useCallback, useMemo, useState, type Dispatch, type SetStateAction } from 'react';
import { useCrossFlow, Journeys } from '@atlassiansox/cross-flow-support';
import { graphql, useFragment } from 'react-relay';
import Lozenge from '@atlaskit/lozenge';
import { Flex, Box, Inline, Pressable, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { IT_SERVICE_MANAGEMENT_COMPANY_MANAGED_KEY } from '@atlassian/jira-common-constants/src/project-template-type-keys.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectCreateDrawer } from '@atlassian/jira-project-create-drawer-context/src/index.tsx';
import type { card_growthRecommendationsInProjectList_TemplateCard$key } from '@atlassian/jira-relay/src/__generated__/card_growthRecommendationsInProjectList_TemplateCard.graphql';
import { servicedeskStandardToPremiumModalEntryPoint } from '@atlassian/jira-servicedesk-standard-to-premium-modal/entrypoint.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useFireCrossFlowAnalyticsEvents } from '@atlassian/surface-analytics';
import {
	PROJECT_CREATE_SOURCE,
	PROJECT_TYPE_TARGETS_MAP,
	getProductTypeCcpReferenceIdMapForEnv,
} from '../../constants.tsx';
import { messages } from '../../messages.tsx';
import { checkShouldPromptJsmTrial } from '../../utils.tsx';
import CardIcon from './icon/index.tsx';
import { buildCrossflowExtensions } from './utils.tsx';

interface Props {
	dataRef: card_growthRecommendationsInProjectList_TemplateCard$key;
	setTemplatePreviewKey: Dispatch<SetStateAction<string | null>>;
	recommendedProductIds: string[];
	index: number;
}
export const TemplateCard = ({
	dataRef,
	recommendedProductIds,
	setTemplatePreviewKey,
	index,
}: Props) => {
	const environment = useEnvironment();
	const { fireInteractedUIEvent } = useFireCrossFlowAnalyticsEvents();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const crossFlow = useCrossFlow();

	const {
		methods: { open: openProjectCreate },
	} = useProjectCreateDrawer();

	const cardEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'card',
	});

	const cardHoveredEvent = createAnalyticsEvent({
		action: 'hovered',
		actionSubject: 'card',
	});

	const template = useFragment<card_growthRecommendationsInProjectList_TemplateCard$key>(
		graphql`
			fragment card_growthRecommendationsInProjectList_TemplateCard on JiraProjectListViewTemplateItem {
				title @required(action: THROW)
				shortDescription @required(action: THROW)
				key @required(action: THROW)
				isProductLicensed @required(action: THROW)
				isPremiumOnly @required(action: THROW)
				canCreate @required(action: THROW)
				productKey @required(action: THROW)
				recommendationSessionId @required(action: THROW)
				templateType @required(action: THROW)
				...icon_growthRecommendationsInProjectList_CardIcon
			}
		`,
		dataRef,
	);

	const { formatMessage } = useIntl();

	// Exposure fired in BE
	const [expConfig] = UNSAFE_noExposureExp('add_jpd_product_roadmap_template_reco_with_new_cb');
	const shouldUseNewRecommendations = expConfig.get('useNewRecommendations', false);

	const {
		title,
		shortDescription,
		key,
		isProductLicensed,
		isPremiumOnly,
		productKey,
		templateType,
		recommendationSessionId,
		canCreate,
	} = template;

	const { shouldPromptJsmTrial } = checkShouldPromptJsmTrial({
		templateKey: key,
		isProductLicensed,
		canCreate,
	});
	const [isJsmPremiumUpgradeModalOpen, setIsJsmPremiumUpgradeModalOpen] = useState(false);
	const entryPoint = useEntryPoint(servicedeskStandardToPremiumModalEntryPoint, {});
	const triggerRef = useEntryPointButtonTrigger(entryPoint.entryPointActions);

	const extensions = useMemo(() => {
		const mergedExtensions = buildCrossflowExtensions({
			templateId: key,
			recommendationSessionId,
			templateType,
		});
		if (
			key === IT_SERVICE_MANAGEMENT_COMPANY_MANAGED_KEY &&
			fg('fix_advanced_itsm_recommendation_cross_flow')
		) {
			Object.assign(mergedExtensions, {
				edition: 'premium',
			});
		}
		return mergedExtensions;
	}, [key, recommendationSessionId, templateType]);

	const onClick = useCallback(() => {
		fireUIAnalytics(cardEvent, 'templateCard', {
			key,
			productKey,
			isProductLicensed,
			isPremiumOnly,
			templateType,
			recommendationSessionId,
			extensions,
		});

		if (shouldPromptJsmTrial) {
			setIsJsmPremiumUpgradeModalOpen(true);
			return;
		}

		if (
			!isProductLicensed &&
			crossFlow.isEnabled &&
			PROJECT_TYPE_TARGETS_MAP[productKey] &&
			getProductTypeCcpReferenceIdMapForEnv(environment)[productKey]
		) {
			fireInteractedUIEvent(
				{
					action: 'clicked',
					actionSubject: 'card',
					actionSubjectId: 'templateCard',
				},
				{
					recommendedProductIds,
					recommendationSession: {
						id: recommendationSessionId,
						type: 'appRec',
					},
					targetProductId: getProductTypeCcpReferenceIdMapForEnv(environment)[productKey],
					source: 'projectsDirectorySidebarSection',
				},
			);
			crossFlow.api.open({
				journey: Journeys.GET_STARTED,
				targetProduct: PROJECT_TYPE_TARGETS_MAP[productKey],
				sourceComponent: 'projectsDirectorySidebar',
				sourceContext: 'templateCard',
				extensions,
			});
			return;
		}

		openProjectCreate({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			selectedProjectType: productKey.toLowerCase() as ProjectType,
			showExperienceSelection: false,
			defaultSelectedTemplateKey: key,
			source: PROJECT_CREATE_SOURCE,
			...(shouldUseNewRecommendations && { recommendationSessionId }),
		});
	}, [
		cardEvent,
		key,
		productKey,
		isProductLicensed,
		isPremiumOnly,
		shouldUseNewRecommendations,
		templateType,
		recommendationSessionId,
		extensions,
		crossFlow,
		environment,
		openProjectCreate,
		fireInteractedUIEvent,
		shouldPromptJsmTrial,
		recommendedProductIds,
	]);

	const onPreviewDismiss = () => {
		setTemplatePreviewKey((current) => {
			if (current === key) {
				return null;
			}
			return current;
		});
	};

	const onPreviewStart = () => {
		fireUIAnalytics(cardHoveredEvent, 'templateCard', {
			key,
			productKey,
			isProductLicensed,
			templateType,
			isPremiumOnly,
		});
		setTemplatePreviewKey(key);
	};

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<Box
				as="span"
				xcss={PaddedCardStyles}
				onMouseEnter={onPreviewStart}
				onMouseLeave={onPreviewDismiss}
			>
				<Pressable
					padding="space.200"
					data-testId={`growth-recommendations-in-project-list.ui.template-card-${index}`}
					xcss={CardStyles}
					onFocus={onPreviewStart}
					onBlur={onPreviewDismiss}
					onClick={onClick}
					tabIndex={0}
					{...(shouldPromptJsmTrial && {
						ref: triggerRef,
					})}
				>
					<Inline space="space.150" alignBlock="center">
						<CardIcon keyRef={template} />
						<Stack as="div" space="space.050">
							<Flex as="span" xcss={TitleStyles}>
								{title}
								{!isProductLicensed && (
									<Lozenge appearance="new">{formatMessage(messages.tryLozenge)}</Lozenge>
								)}
								{isProductLicensed && isPremiumOnly && (
									<Lozenge appearance="new">{formatMessage(messages.premiumLozenge)}</Lozenge>
								)}
							</Flex>
							<Box as="p" xcss={DescriptionStyles}>
								{shortDescription}
							</Box>
						</Stack>
					</Inline>
				</Pressable>
			</Box>
			{isJsmPremiumUpgradeModalOpen && (
				<ModalEntryPointContainer
					entryPointReferenceSubject={entryPoint.entryPointReferenceSubject}
					id="standard-to-premium-modal"
					packageName="jira-cmdb-upsell-page"
					teamName="ITOps Mithril" // assigning to Mithril for the experiment
					entryPointActions={entryPoint.entryPointActions}
					runtimeProps={{
						onClose: () => {
							setIsJsmPremiumUpgradeModalOpen(false);
						},
						origin: 'templateCard',
					}}
				/>
			)}
		</>
	);
};

const PaddedCardStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	paddingBlock: 'space.100',
});

const CardStyles = xcss({
	width: '100%',
	textAlign: 'left',
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	':hover': {
		background: 'elevation.surface.overlay',
		boxShadow: 'elevation.shadow.overlay',
	},
	':focus': {
		background: 'elevation.surface.overlay',
		boxShadow: 'elevation.shadow.overlay',
	},
});

const TitleStyles = xcss({
	font: token('font.body'),
	color: 'color.text',
	gap: 'space.050',
});

const DescriptionStyles = xcss({
	margin: '0',
	color: 'color.text.subtlest',
	font: token('font.body.UNSAFE_small'),
});
