import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { SearchControl } from './search-control';

type Props = {
	children: React.ReactElement;
};

export const RefinementBar = ({ children }: Props) => {
	return fg('blu-6146-responsive-directories') ? (
		<Box xcss={wrapperStyles}>{children}</Box>
	) : (
		<Wrapper>{children}</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	gap: token('space.200', '16px'),
	flex: '1',
	marginBottom: token('space.200', '16px'),
});

const wrapperStyles = xcss({
	marginBottom: 'space.200',
});
